<template>
  <section class="computer_container">
    <article class="title">{{ $t('雇佣成本计算器') }}</article>
    <article class="sub_title">
      {{ $t('准确评估潜在的全球招聘成本，解放人力资源和薪酬计算团队') }}
    </article>
    <section class="computers">
      <section class="item_box example">
        <article class="title">{{ $t('新加坡') }}</article>
        <section class="content">
          <article class="row">
            <span class="key">{{ $t('年度薪资') }}</span
            ><span class="price">USD 1000.0</span>
          </article>
          <article class="row">
            <span class="key">{{ $t('雇主成本') }}</span
            ><span class="price">USD 172.56</span>
          </article>
          <section class="pop_container">
            <section class="root">
              <section class="item">
                <span class="key">{{ $t('技能发展税（SDL）') }}</span
                ><span class="value">USD 2.52</span>
              </section>
              <section class="item">
                <span class="key">{{ $t('中央公积金（CPF）') }}</span
                ><span class="value">USD 172.04</span>
              </section>
            </section>
          </section>

          <article class="row">
            <span class="key">{{ $t('年度薪资') }}</span
            ><span class="price">USD 1172.56</span>
          </article>
          <!-- <section class="pop_container">
          </section> -->
        </section>
      </section>
      <section class="item_box example">
        <article class="title">{{ $t('印度尼西亚') }}</article>
        <section class="content">
          <article class="row"><span class="key">{{ $t('年度薪资') }}</span><span class="price">USD 1000.0</span></article>
          <article class="row"><span class="key">{{ $t('雇主成本') }}</span><span class="price">USD 724.80</span></article>
          <section class="pop_indian_container">
            <section class="root">
              <section class="item"><span class="key">{{ $t('节约储金') }}</span><span class="value">USD 36.96</span></section>
              <section class="item"><span class="key">{{ $t('死亡保险') }}</span><span class="value">USD 3.00</span></section>
              <section class="item"><span class="key">{{ $t('工伤事故津贴') }}</span><span class="value">USD 2.40</span></section>
              <section class="item">
                <span class="key">{{ $t('医疗和社会保障') }}</span><span class="value">USD 500.04</span>
              </section>
              <section class="item"><span class="key">{{ $t('养恤基金') }}</span><span class="value">USD 182.40</span></section>
            </section>
          </section>
          <article class="row"><span class="key">{{ $t('年度成本') }}</span><span class="price">USD 1724.80</span></article>
        </section>
      </section>
      <section class="item_box computer">
        <article class="c_title">{{ $t('选择更多国家') }}</article>
        <section class="form">
          <section class="form_item">
            <article class="field">{{ $t('国家') }}</article>
            <input :placeholder="$t('请选择')" class="op" @click="jump" />
            <!-- <option disabled class="option" selected>请选择</option>
            </input> -->
          </section>
          <section class="form_item">
            <article class="field">{{ $t('年度薪资') }}</article>
            <input :placeholder="$t('请输入（默认使用美元换算）')" class="op" @click="jump" />
          </section>
          <a-button type="primary" class="button" @click="jump"> {{ $t('开始计算') }} </a-button>
        </section>
        <article class="attention">{{ $t('雇佣成本为预估值，且会受不同管辖区的法律政策影响。仅供参考') }}</article>
      </section>
    </section>
  </section>
</template>

<script setup lang="ts">
const router = useRouter();
function jump() {
  router.push('/resource/cost-calculator');
}
</script>

<style lang="less" scoped>
@media screen and(min-width:800px) {
  .computer_container {
    background-image: url('https://video.reta-inc.com/image/default/B158E9FFEBFF4858B12F711121604933-6-2.jpeg');
    padding: 80px 0 120px;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 64px;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-align: center;
    }

    .sub_title {
      margin-top: 6px;
      font-size: 24px;
      line-height: 34px;
      color: #ffffff;
      text-align: center;
    }

    .computers {
      display: flex;
      margin: 60px auto 0;
      width: fit-content;
      gap: 40px;

      .item_box {
        width: 400px;
        height: 478px;
        background: #e5edff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #1d2129;
          padding: 19px 32px;
        }

        .content {
          flex: 1;
          background: #ffffff;
          border-radius: 20px;
          padding: 32px 16px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;

            .key {
              font-size: 20px;
              line-height: 28px;
              color: #4e5969;
            }

            .price {
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              color: #1d2129;
            }
          }

          .root {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .item {
              display: flex;
              justify-content: space-between;
              padding: 0 16px;

              .key {
                font-size: 16px;
                line-height: 22px;
                color: #4e5969;
              }

              .value {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                text-align: right;
                color: #4e5969;
              }
            }
          }

          .pop_container {
            margin-top: -12px;
            width: 100%;
            height: 100px;
            background-image: url('https://video.reta-inc.com/image/default/3D85C7517A364CC3A8E31AFD472429C8-6-2.jpg');
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .pop_indian_container {
            margin-top: -12px;
            width: 100%;
            padding: 20px 0 10px;
            background-image: url('https://video.reta-inc.com/image/default/19945512CD1C4460948E4877DD6E6A8F-6-2.jpg');
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .computer {
        border: 2px solid #ffffff;

        .c_title {
          padding: 28px 32px;
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #1d2129;
        }

        .form {
          margin-top: 12px;
          padding: 0 32px;
          display: flex;
          flex-direction: column;
          gap: 32px;

          .form_item {
            .field {
              font-size: 18px;
              line-height: 22px;
              color: #1d2129;
            }

            .op {
              margin-top: 12px;
              width: 100%;
              box-sizing: border-box;
              border: none;
              padding: 13px 12px;
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
              appearance: none;
              border-radius: 4px;
            }

            .op::-webkit-input-placeholder {
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
            }

            .op:focus {
              outline: 0;
              border: none;
            }

            .option {
              width: 100%;
              box-sizing: border-box;
              border: none;
              padding: 13px 12px;
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
            }
          }

          .button {
            margin-top: 27px;
            height: 48px;
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
          }
        }

        .attention {
          margin: 17px 0;
          font-size: 11px;
          line-height: 16px;
          text-align: center;
          color: #86909c;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
@media screen and(max-width:800px) {
  .computer_container {
    background-image: url('https://video.reta-inc.com/image/default/B158E9FFEBFF4858B12F711121604933-6-2.jpeg');
    padding: 40px 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
    }

    .sub_title {
      margin-top: 6px;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
    }

    .computers {
      display: flex;
      margin: 24px auto 0;
      width: fit-content;
      gap: 40px;

      .example {
        display: none !important;
      }

      .item_box {
        padding: 0 16px;
        background: #e5edff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #1d2129;
          padding: 19px 32px;
        }

        .content {
          flex: 1;
          background: #ffffff;
          border-radius: 20px;
          padding: 32px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .key {
              font-size: 20px;
              line-height: 28px;
              color: #4e5969;
            }

            .price {
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              color: #1d2129;
            }
          }

          .pop_container {
            border-style: solid;
            border-width: 0 1px 1px 1px;
            position: relative;
            border-color: #386aff #386aff #386aff #386aff;

            &::before {
              content: '';
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #386aff transparent;
              position: absolute;
              top: -10px;
            }
          }

          .popover {
            margin-top: -20px;
          }
        }
      }

      .computer {
        border: 2px solid #ffffff;

        .c_title {
          padding: 24px 20px;
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #1d2129;
        }

        .form {
          margin-top: 12px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          gap: 32px;

          .form_item {
            .field {
              font-size: 18px;
              line-height: 22px;
              color: #1d2129;
            }

            .op {
              margin-top: 12px;
              width: 100%;
              box-sizing: border-box;
              border: none;
              padding: 13px 12px;
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
            }

            .op::-webkit-input-placeholder {
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
            }

            .op:focus {
              outline: 0;
              border: none;
            }

            .option {
              width: 100%;
              box-sizing: border-box;
              border: none;
              padding: 13px 12px;
              font-size: 16px;
              line-height: 22px;
              color: #86909c;
            }
          }

          .button {
            border-radius: 4px;
            height: 40px;
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
          }
        }

        .attention {
          margin: 17px 0;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          color: #86909c;
        }
      }
    }
  }
}
</style>
